
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmUploadArea from '@/components/shared/TmUploadArea.vue'
import { useModals } from '@/compositions/modals'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import type { PropType } from '@vue/runtime-core'

export default defineComponent({
  name: 'AttachFileModal',
  components: { TmDropdown, TmUploadArea, TmButton, TmModal },
  props: {
    title: {
      type: String,
      default: 'Attach a file',
    },
    mmsFormats: {
      type: Boolean,
    },
    infos: {
      type: Array as PropType<string[]>,
      default: () => [
        'You can upload files that are up to 10 MB in size.',
        'A clickable hyperlink will be included in your SMS where recipients can view / download your file.',
        'Attachments will be stored on Textmagic\'s servers.',
      ],
    },
  },
  setup() {
    const { openModal } = useModals()
    const file = ref()
    const mmsFileFormats = '.json, .ogv, .oga, .ogx, .ogg, .rtf, .zip, .tar, .xml, .gz, .bz2, .gz, .smil, .m4a, .m4p, .m4b, .m4r, .mp1, .mp2, .mp3, .m1a, .m2a, .mpa, .oga, .flac, .webm, .wav, .amr, .3ga, .3gp, .bmp, .dib, .gif, .jpg, .jpeg, .pjpeg, .png, .svg, .tiff, .tif, .webp, .ico, .css, .csv, .html, .cal, .txt, .vcf, .vcard, .wap, .xml, .avi, .mp4, .m4v, .mpg, .mpeg, .m1v, .mpv, .ogv, .ogx, .ogg, .spx, .ogm, .mov, .qt, .webm, .wmv, .flv'
    const fileFormats = ['jpg', 'gif', 'png', 'pdf', 'txt', 'csv', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'vcf']
    const fileFormatsText = fileFormats.reduce((acc, curr, i) => {
      if (i === fileFormats.length - 1) {
        acc = `${acc = acc.slice(0, -2)} & .${curr} file formats`
      } else {
        acc += `.${curr}, `
      }
      return acc
    }, 'Supports ')

    const upload = (hideModal: () => void) => {
      openModal('uploadedFile')
      hideModal()
    }

    return {
      file,
      mmsFileFormats,
      fileFormats,
      fileFormatsText,
      upload,
    }
  },
})
